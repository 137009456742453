import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token,  config} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel } from '../component/globalFunction'


 const Setup =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
const [timetable, setTimetable] = useState({
    startTime:'',
    endTime:'',
    periodNumber:'',
    schoolClass:[]
})
const [schoolClass, setSchoolClass]=useState([]);
const [errors, setErrors] = useState({});
const [periodList, setPeriodList] = useState([]);
const [isSubmitting, setIsSubmitting] = useState(false); 
const [activeStep, setActiveStep] = useState(1);
const [activities, setActivities]=useState([])


const [CurrentSession, setCurrentSession] = useState('')
const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }
    const steps = [{title: 'Days Setup'}, {title: 'Period Setup '}]
    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
       
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])
    const [dayList, setDayList] = useState([
   
       {"day":"Monday", "status":'working', "ID":'1', "active":"Yes" },
        {"day":"Tuesday", "status":'working', "ID":'2' , "active":"Yes"},
        {"day":"Wednessday", "status":'working', "ID":'3', "active":"Yes" },
        {"day":"Thursday", "status":'working', "ID":'4', "active":"Yes" },
        {"day":"Friday", "status":'working', "ID":'5', "active":"Yes" },
        {"day":"Saturday", "status":'holiday', "ID":'6', "active":"Yes" },
        {"day":"Sunday","status":'holiday',"ID":'7', "active":"Yes" }
    ])

    const handleSelect = (option, action)=>{
        
        setTimetable({...timetable, [action.name]: option});
        setErrors({...errors, [action.name]:''})
       
         }
         const handleChange = event =>{
            const {name, value} = event.target
           setTimetable({...timetable, [name] : value });
           setErrors({...errors, [name]:''})
    
           if(name==='periodNumber'){
            periodSetup(value);
           }
        }

    const  handleFetchClass =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
            
    }
    const  handleSession =()=>{

        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        }
    const  fetchActivities =()=>{  
        
        const fd = new FormData();
        fd.append('jwt', Token);
   let sql = "Select ID, activity from tbl_activities order by activity ASC"
       let url = ServerUrl+'/fetchBySql_controller'
       fd.append("sql", sql)
       axios.post(url, fd, config).then(result =>setActivities(result.data)) 
   
    }  

    const periodSetup =(num)=>{
        var value = [];
        for (var i = 1; i <= Number(num); i++) {
          value.push({ID:Math.random().toString(36).substr(2, 9), periodName:i, startTime:i===1?timetable.startTime:'', endTime:i===Number(num)?timetable.endTime:'', periodType:'', subject:'', staff:'', chapter:'', topic:''});
        }
        setPeriodList(value)
    }

    const handleChangePeriod = (e, index)=>{
        const std =JSON.parse(JSON.stringify(periodList))   
            const value =e.target.value;
           std[index][e.target.name] = value;
        setPeriodList(std);
    }

    const handleChangeCheckbox =(index)=>{
        
        const list =JSON.parse(JSON.stringify(dayList))   
       const value = list[index].active ==='Yes'? 'No':'Yes';
       list[index].active = value;
       setDayList(list); 
            
           }

        const handleChangeDayList = (e, index)=>{
        const std =JSON.parse(JSON.stringify(dayList))   
            const value =e.target.value;
            std[index].status = value;
        setDayList(std);
    }

    
    const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(timetable));
        setIsSubmitting(true);
    } 

   
    const ValidateInput =(values)=>{
        let errors = {};   
       
        if(values.schoolClass.length===0){
            errors.schoolClass ='Please select class';
        }
        if(!values.periodNumber){
            errors.periodNumber ='Please enter No Of Period In A Day';
        }
        
        if(periodList.length===0){
            errors.periodNumber ='Please enter No Of Period In A Day';
        }
        return errors;
        }
 

function submit(){ 
   
            
    const fd = new FormData();
    fd.append('jwt', Token);
        var working = [];
        var holiday = [];
    var options = dayList

    for (var i = 0, l = options.length; i < l; i++) {
        options[i].status==='working'&&options[i].active==='Yes'?
          working.push({day:options[i].day}):holiday.push({day:options[i].day})
      }

      
        fd.append('daysList', JSON.stringify(working, null, 2));
        fd.append('classList',  JSON.stringify(timetable.schoolClass, null, 2))
        fd.append('sessionYear',  CurrentSession)  
        fd.append('periodList',  JSON.stringify(periodList, null, 2))
 setNotice({...notice, 
        isLoading: true}) 
       let url = ServerUrl+'/save_controller/tbl_timetable_setup'
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Update!', 'default', response.data.message)
                            }else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setActiveStep(1)
                setNotice({...notice, 
                    isLoading: false}) 
       setTimetable({
           schoolClass:[],
        startTime:'',
        endTime:'',
        periodNumber:''
       })
       setPeriodList([])
    })  
}


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

    
    
    useEffect(()=>{
        handleFetchClass()
        fetchActivities()
        handleSession()
        },[])
    

        return (  
            <React.Fragment>
   <Template menu="Timetable" submenu='Automatic Timetable Setup' > {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

        <PageHeader title="Generate Timetable">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Automatic Generate</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Automatic Timetable</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximize full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '10px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="Expenses">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">

   <div className="row">
   <section className="col-md-4"><b>Day</b></section>
   <section className="col-md-4"><b>Working</b></section>
   <section className="col-md-4"><b>Holiday</b></section>
    </div>
<br/>
{dayList.map((list, idx)=>
    <div key={idx} className="row">
                           
       <section className="col-md-4">
       <div className="checkbox-color checkbox-primary">
        <input id={list.day} name={list.day} value={list.active} type="checkbox" onChange={()=>handleChangeCheckbox(idx)} checked={list.active==='Yes'? true:false}  /> 
                     <label htmlFor={list.day}>{list.day}</label>
        </div>

      </section>
      
   <section className="col-md-4">

   <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={list.status ==="working"} onChange={(e)=>handleChangeDayList(e, idx)} value="working" name={list.ID} />
							<i className="helper"></i></label></div>
</div>
   </section>
   <section className="col-md-4">
   <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={list.status ==="holiday"} onChange={(e)=>handleChangeDayList(e, idx)} value="holiday" name={list.ID} />
							<i className="helper"></i></label></div>
	</div>
   </section>


          </div> )}
   
          </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-6">
       <div className="form-group">
<label >Start Time</label>
<input type="time" className='form-control' name="startTime"  onChange={handleChange} value={timetable.startTime} />
   </div>

       <div className="form-group">
<label >No Of Period In A Day</label>
<input type="number" min="1" className={errors.periodNumber ? 'form-control form-control-danger' : 'form-control'} name="periodNumber"  onChange={handleChange} value={timetable.periodNumber} />
<span style={{color:'red'}}>{errors.periodNumber && errors.periodNumber}</span>
   </div>

       </section>


       <section className="col-md-6">
       <div className="form-group">
<label >End Time</label>
<input type="time" className='form-control' name="endTime"  onChange={handleChange} value={timetable.endTime} />

   </div>

       <div className="form-group">
						<label >Select Class<span style={{color:'red'}}>*</span></label>
						<Select isMulti options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} name="schoolClass" className={errors.schoolClass ? 'form-control form-control-danger' : ''}  value={timetable.schoolClass}  /> <span style={{color:'red'}}>{errors.schoolClass && errors.schoolClass}</span>
												
											</div>


       </section>
               </div>


               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>
	  
	 
      :    <div className="row setup-content" id="periodSetup">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      
   <div className="row">
   <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
                    <th>Period Name</th>
                    <th> Start Time</th>
                    <th> End Time</th>
                    <th>Type</th>   
                </tr>
            </thead>
            <tbody>
                {periodList&&periodList.map((pl, id)=>
            <tr key={id}>
                    <td><input type="text" className="form-control" name='periodName' value={pl.periodName}  onChange={(e)=>handleChangePeriod(e, id)} /></td>
                    <td><input type="time" className="form-control" name='startTime' value={pl.startTime}  onChange={(e)=>handleChangePeriod(e, id)} /></td>
                    <td><input type="time" className="form-control" name='endTime' value={pl.endTime}  onChange={(e)=>handleChangePeriod(e, id)} /></td>
                    <td>
                    <select onChange={(e)=>handleChangePeriod(e, id)}  name="periodType"  value={pl.periodType} className="form-control">
                        <option value="">Select Type</option>
                        { activities&&activities.map((list, idx)=> {
                               return  <option key={idx} value={list.ID} >{list.activity}</option> })}
                        </select>
                    </td>
                </tr>)}
               
            </tbody> 
        </table>
       
   </div>
  

 </div>	
 
<div style={{color:'red'}}>{errors.periodNumber && errors.periodNumber}</div>
  </div>   

                             
                              
      </div>	
          	</div>
      
      </div>	
           
      </div>
   
</div>}

                <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
                
                </div>
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
</React.Fragment>
 );
        
}

export default React.memo(Setup) 