import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, ImagesUrl} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Select from 'react-select'
const Books =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
   
    const [books, setBooks]= useState([])    
    const [subject, setSubject]= useState([]) 
    
 const [library, setLibrary] = useState({
     bookName:'',
     authorName:'',
     subject:[],
     publisher:'',
     bookView:{value:'card', label:'Card'}
 })
    
    const fetchSubject =()=>{
        const fd = new FormData();
      fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }
    


    const fetchBooks =()=>{
        setNotice({...notice, isLoading: true}) 
        let sql = "Select  b.ID, b.code, b.bookName, b.imageUrl,  b.subjectId,  b.barcode, b.authorName, b.publisher, s.subjectName, b.purposeId, (select sum(issuedCount)  from tbl_book_issued where returnStatus ='No' and bookCode = b.code group by b.code) as totalIssued, b.totalBook  from tbl_books b, tbl_subject s  where b.subjectId = s.code "

        if(library.subject.length!==0){
            sql = sql + ' and b.subjectId ="'+library.subject.value+'"'
        }
        if(library.bookName!==''){
            sql = sql + ' and b.bookName Like "%'+library.bookName+'%"'
        }

        if(library.authorName!==''){
            sql = sql + ' and b.authorName Like "%'+library.authorName+'%"'
        }
        

        sql = sql + ' order by b.subjectId'
        const fd = new FormData();
      fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{ 
            setBooks(result.data)
            setNotice({...notice, isLoading: false}) 
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })

        }



    const handleSelect = (option, action)=>{
          setLibrary({...library, [action.name]:option})
    }


    const handleChange = (e)=>{
        const {name, value} = e.target
        setLibrary({...library, [name]:value})
    }



const tableHeader = [
        {dataField: 'bookName', text: 'Book Name'},
        {dataField: 'authorName', text: 'Author Name'},       
        {dataField: 'publisher', text: 'Publisher'},
    {dataField: 'subjectName', text: 'Subject', editable: false},
    {dataField: 'totalBook', text: 'Total Books', editable: false},
    {dataField: 'totalIssued', text: 'Total Available', editable: false, formatter:(cell, row)=>Number(row.totalBook)- Number(row.totalIssued)}
 ];
 



 const BookTable=(props)=>{


    
     const { SearchBar } = Search;
 
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
         showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;
                           
                         
                             </div>
                             
                      
             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }





useEffect(()=>{
    fetchSubject()
},[]);



        return (  
            <React.Fragment>
   <Template menu="Library" submenu='Book Availability'>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Library Books">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Library</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Books</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
                <div  className="row  ">
							
                               
                          <div className="col-sm-12">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Search Book</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
	
         	<div  className="row">
									
										<section className="col-md-6">
                                    
                                    
                                        <div className="form-group">
						<label >Subject</label>
                        <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value:list.code, label:list.subjectName, abr:list.abbreviation}
                             })} 
onChange={handleSelect}  name="subject"  value={books.subject}  /> 
			</div>
            </section>
          
            <section className="col-md-6">
										<div className="form-group">
						<label >Book Name</label>
		<input type="text" name="bookName" value={library.bookName} onChange={handleChange} className='form-control' /> 	
			</div></section>


            <section className="col-md-6">
            <div className="form-group">
						<label >Author Name</label>
		<input type="text" name="authorName" value={library.authorName} onChange={handleChange} className='form-control' /> 	
			</div></section>
           
											
            <section className="col-md-6">
                                    
                                    
                                    <div className="form-group">
                    <label >Book View</label>
                    <Select options={[
                        {value:'card', label:'Card'},
                     {value:'list', label:'List'}]} 
onChange={handleSelect}  name="bookView"  value={library.bookView}  /> 
        </div>
        </section>
        </div>
                          <div  className="row">
                                        <section className="col-md-4">
	<footer className="pull-left">
	
	<button type="button" id="submit" onClick={()=>fetchBooks()} className="btn btn-inverse "> <i className='fa fa-search'></i> Search Book</button>
	
								</footer>	
                                </section>			
							</div>
							</div>
                            </div>
                            </div>
                            
                            </div>


{library.bookView.value==='list'?

<div className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Books</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <BookTable data={books}  columns={tableHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>:








<div  className="row simple-cards users-card">
{books&&books.map((item,id)=>
    <div key={id} className="col-md-12 col-xl-3">
        <div className="card user-card">
            <div className="card-body ">
                <img className="img-fluid img-radius " onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpg"}} src={ImagesUrl+'/library/books/'+item.imageUrl} alt="card-img" />
                <h4>{item.bookName}</h4>
                <h5>By:</h5>
                <h6>{item.authorName}</h6>
            </div>
            <p>{item.publisher}</p>
            <div className="row justify-content-center">
               
                <div className="col-auto" title="Total Available">
                    <div className="label-icon">
                        <i className="icofont icofont-book"></i>
                        <label className="badge badge-success badge-top-right" >{Number(item.totalBook)- Number(item.totalIssued)}</label>
                    </div>
                </div>
               
            </div>

            
           
        </div>
    </div>)}
</div>
}








   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
</React.Fragment>
 );
        
}

export default React.memo(Books) 