import React, {useState, useEffect} from 'react'
import {useHistory, useParams} from 'react-router'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, ImagesUrl, Token, config} from '../component/include'
import { getClassName, longDate } from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'

const StudentProfile =()=>{
    let history = useHistory();
    let params = useParams()
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const countries  = useSelector(state => state.rootReducer.countries);

    const [student, setStudent] = useState([]);
const [documents, setDocuments]=useState([])
const [occupation, setOccupation]=useState([])
const [caste, setCaste]=useState([])
const [family, setFamily]=useState([])

const [states, setStates]=useState([])
const [city, setCity]=useState([])


 const fetchCountryName =(code)=>{
        const result = countries&&countries.filter(list=>list.iso2===code);
      const answer = result.length!==0?result[0].name:''
        return answer
      }


     const handleFetchState = ()=>{
        const fd = new FormData(); 
        var sql ="Select s.name from tbl_states s, tbl_students st where st.stateId = s.id and st.code ='"+params.code+"'";  
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result =>{
        if (result.data.length>0){
            setStates(result.data[0].name)
        }else{
            setStates('')
        }
    })   
     }

     const handleFetchCities = ()=>{
        const fd = new FormData(); 
        var sql ="Select c.name from tbl_cities c, tbl_students st where st.lga = c.id and st.code ='"+params.code+"'"; 
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result =>{

        if (result.data.length>0){
            setCity(result.data[0].name)
        }else{
            setCity('')
        }
    })   
     }


const fetchOccupation =()=>{
    var sql ="SELECT occupation, document, house, club, position, terminationReason, program from  tbl_student_master where ID="+1;
  const fd = new FormData();
  fd.append("sql", sql);
  fd.append('jwt', Token)
  let url = ServerUrl+'/fetchBySql_controller';
  axios.post(url, fd, config).then(result=>{
  if(result.data.length>0){
   // setClub(JSON.parse(result.data[0].club))
    //setPosition(JSON.parse(result.data[0].position))
  setOccupation(JSON.parse(result.data[0].occupation))
  setCaste(JSON.parse(result.data[0].house))
  setDocuments(JSON.parse(result.data[0].document))
  //setTermination(JSON.parse(result.data[0].terminationReason))
  }
  })
  .catch((error)=>{
  Alerts('Error!', 'danger', error.message)
  })
  }
  



const fetchFamily =()=>{
    const fd = new FormData(); 
        fd.append("jwt", Token);
    let url = ServerUrl + '/fetch_controller/tbl_family'
    axios.post(url, fd, config)
    .then(result =>setFamily(result.data))
}

const fetchField =(code, table, field)=>{
    const result = table.filter(list=>list.code===code);
  const answer = result.map(ans=>ans[field])
    return answer
  }

 
    
          
    const handleFetchStudent =()=>{
        setNotice({...notice,  isLoading: true})
        var sql ="Select *  from tbl_students where code ='"+params.code+"' "; 
        const fd = new FormData(); 
        fd.append("jwt", Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setStudent(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isLoading: false})
    })
    }
    

useEffect(()=>{    
    fetchFamily()
    handleFetchCities()
    handleFetchState()
    fetchOccupation()

    
    handleFetchStudent()
},[]);

        return (  <Template menu="Student" submenu='View Student' >{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper"> 

        <PageHeader title="Profile">
        <li className="breadcrumb-item"><a  href="/dashboard"> <i className="fa fa-dashboard"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="/students/view_students">Students</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Profile</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div>



                {/* <!-- Edit student start here  --> */}
            {student&&student.map((item, idx)=>    <div key={idx} className="row">
              <div className="col-md-6">
                <div className="card z-depth-0">
        
        <div className="card-block">
                                    
        <div  className="row">
        <div className="col-lg-12">
            <div className="cover-profile">
                <div className="profile-bg-img">
              


            <img className="profile-bg-img img-fluid" src={ImagesUrl+'/students/'+item.cover} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/background/f.jpeg'}} style={{height:'200px'}}  alt="bg-img"/>
                    <div className="card-block user-info">
                        <div className="col-md-12">
                            <div className="media-left">
                                <a href="#!" className="profile-image">
                <img className="user-img img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/students/'+item.gender+'.png'}} src={`${ImagesUrl}/students/${item.passport}`} alt="user-img" style={{display:'block', height:'150px',width:'150px'}} /> </a> </div>
                            <div className="media-body row">
                                <div className="col-lg-12">
                                    <div className="user-title">
                                        <h2>{item.studentName}</h2>
                                        <h2 >{getClassName(item.classID, item.sectionID, schoolClass)}</h2>
                                    </div></div><div>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div className="row ">
<div className="col-sm-1"></div>
    <div className="col-sm-6">
    <br/>
    <div className="text-muted txt-color-darken"><b>Register No.</b>:&nbsp;{item.registerNumber}</div>
        
       <p className="text-muted"> <span className="txt-color-darken"><b>Admission No: </b>&nbsp;{item.admissionNumber}</span> </p> 
<div className="text-muted"> <span className="txt-color-darken"><b>Birth Date: </b>&nbsp;{longDate(item.dateOfBirth)}</span> </div> 
    <div className="text-muted"> <span className="txt-color-darken"><b>Gender: </b>&nbsp;{item.gender}</span> </div> 

        <div className="text-muted"> <span className="txt-color-darken"><b>Blood Group: </b>&nbsp;{item.	bloodGroup}</span> </div> 
       <p className="text-muted"> <span className="txt-color-darken"><b>House: </b>&nbsp;{fetchField(item.house, caste, 'houseName')}</span> </p> 

           <div className="text-muted txt-color-darken"><b>Family Name</b>:&nbsp;{fetchField(item.family_name, family, 'familyName')}</div>

              
                                        
        <p className="font-md" ><i >Present Address</i></p><p> {item.currentAddress}</p>
            
        <p className="font-md" ><i >Permanent Address</i></p><p> {item.permAddress}</p>

    <p className="font-md" ><i >Admission Status</i></p>{item.admissionStatus==='Studying'?<span className="pcoded-badge label label-success">{item.admissionStatus}</span>:item.admissionStatus==='Graduated'? <span className="pcoded-badge label label-info">{item.admissionStatus}</span>:<span className="pcoded-badge label label-danger">{item.admissionStatus}</span>}
    </div>
    <div className="col-sm-5">
        <h5><small >Contact Info</small></h5>
    <ul className="list-unstyled">
        <li> <p className="text-muted">
                <i className="fa  fa-mobile"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.mobile}</span>
            </p> </li>
            <li><p className="text-muted"><i className="fa fa-at"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.email}</span></p></li>
        <li><p className="text-muted"><i className="fa fa-mobile-phone"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.altMobile}</span></p></li>
       
                <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Tribe </b>:&nbsp;{item.tribe}</span> </p> </li>
                    <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Place of Birth </b>:&nbsp;{item.placeOfBirth}</span> </p> </li>
                    <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Language Spoken </b>:&nbsp;{item.languageSpoken}</span> </p> </li>


                <li><p className="text-muted">
                    <span className="txt-color-darken"><b>State </b>:&nbsp;{states&&states}</span> </p> </li>
                    <li><p className="text-muted">
                    <span className="txt-color-darken"><b>City </b>:&nbsp;{city&&city}</span> </p> </li>

        <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Country </b>:&nbsp;{countries&&fetchCountryName(item.country)}</span> </p> </li>
       

                    <form method='POST' action={ServerUrl+'/printout/student_profile.php?studentCode='+params.code+'&token='+userToken} target='_blank'>
                <input type='hidden' name='jwtToken' value ={Token} />
                {/* <button type="submit" className="btn btn-inverse pull-right " > <i className="fa fa-print"></i> Print</button> */}
        
        </form>


    </ul>
                    </div>
                </div>
           
            
            
            
    
    </div> </div>
            </div> 
            
            


<div className="col-md-6">
                <div className="card z-depth-0">
        
        <div className="card-block">



       
        <ul className="nav nav-tabs md-tabs " role="tablist">
           {/*  <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#parent" role="tab" aria-expanded="true"><i className="icofont icofont-users"></i> Parent</a>
                <div className="slide"></div>
            </li> */}
            <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#siblings" role="tab" aria-expanded="false"><i className="icofont icofont-ui-user "></i>Siblings</a>
                <div className="slide"></div>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#qualification" role="tab" aria-expanded="false"><i className="fa fa-file-text"></i> Qualification</a>
                <div className="slide"></div>
            </li>
            <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#settings7" role="tab" aria-expanded="false"><i className="icofont icofont-ui-settings"></i> Documents</a>
                <div className="slide"></div>
            </li>
        </ul>
       

        <div className="tab-content card-block">
            <div className="tab-pane " id="parent" role="tabpanel" aria-expanded="true">
                <div className='card text-center'> <h4>Parent Details</h4></div>
                <div className='table-responsive'>
          {/*   <table  className="table table-striped table-bordered table-hover">
							<thead>			                
								<tr>
									<th></th>
									<th >Father</th>
									<th >Mother</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td ><b >Name</b></td>
									<td>{item.fatherName}</td>
									<td>{item.motherName}</td>
								</tr>
								<tr>
									<td><b >Birth Date</b></td>
									<td>{longDate(item.fatherDOB)}</td>
									<td>{longDate(item.motherDOB)}</td>
								</tr>
								<tr>
									<td ><b >Mobile</b></td>
									<td>{item.father_mobile}</td>
									<td>{item.mother_mobile}</td>
								</tr>
								<tr>
									<td ><b >Email</b></td>
									<td>{item.fatherEmail}</td>
									<td>{item.motherEmail}</td>
								</tr>
								<tr>
									<td ><b >Qualification</b></td>
									<td>{fetchField(item.fatherQualification, documents, 'documentName')}</td>
									<td>{fetchField(item.motherQualification, documents, 'documentName')}</td>
								</tr>
								<tr>
									<td ><b >Occupation</b></td>
									<td>{fetchField(item.fatherOccupation, occupation, 'occupationName')}</td>
									<td>{fetchField(item.motherOccupation, occupation, 'occupationName')}</td>
								</tr>
								<tr>
									<td ><b >Designation</b></td>
									<td>{item.fatherDesignation}</td>
									<td>{item.motherDesignation}</td>
								</tr>
								<tr>
									<td ><b >Organization</b></td>
									<td>{item.fatherOrganization}</td>
									<td>{item.motherOrganization}</td>
								</tr>
							</tbody>
						</table> */}
                        
                        
                        </div>
            </div>
            <div className="tab-pane active" id="siblings" role="tabpanel" aria-expanded="false">
                
            <div className='card text-center'> <h4>Siblings</h4></div>

            <div className='table-responsive'>
            <table  className="table table-striped table-bordered table-hover">
							<thead>			                
								<tr>
                                    <th>SNo.</th>
									<th >Name</th>
									<th >Date of Birth</th>
                                    <th >School</th>
                                    <th >Class</th>
                                    <th >Remarks</th>
								</tr>
							</thead>
							<tbody>
                        {JSON.parse(item.siblings).map((data, id)=>
                        <tr key={id}>
                            <td>{id+1}</td>
                            <td>{data.siblingName} </td>
                            <td>{longDate(data.siblingDOB)} </td>
                            <td>{data.school} </td>
                            <td>{data.siblingClass} </td>
                            <td>{data.siblingRemarks} </td>
                        </tr>
                        )}
                            </tbody>

                            </table>

           
                 </div>
            </div>
            <div className="tab-pane" id="qualification" role="tabpanel" aria-expanded="false">
            <div className='card text-center'> <h4>Institution</h4></div>

<div className='table-responsive'>
<table  className="table table-striped table-bordered table-hover">
                <thead>			                
                    <tr>
                        <th>SNo.</th>
                        <th >Institution</th>
                        <th >Program</th>
                        <th >Grade</th>
                        <th >Year</th>
                        <th >Remarks</th>
                    </tr>
                </thead>
                <tbody>
            {JSON.parse(item.qualifications).map((data, id)=>
            <tr key={id}>
                <td>{id+1}</td>
                <td>{data.institution} </td>
                <td>{data.program} </td>
                <td>{data.grade} </td>
                <td>{data.awardYear} </td>
                <td>{data.remarks} </td>
            </tr>
            )}
                </tbody>

                </table>


     </div>
            </div>
            <div className="tab-pane" id="settings7" role="tabpanel" aria-expanded="false">
            <div className='card text-center'> <h4>Document</h4></div>

<div className='table-responsive'>
<table  className="table table-striped table-bordered table-hover">
                <thead>			                
                    <tr>
                        <th>SNo.</th>
                        <th >Title</th>
                        <th >Type</th>
                        <th >Action</th>
                    </tr>
                </thead>
                <tbody>
            {JSON.parse(item.documents).map((data, id)=>
            <tr key={id}>
                <td>{id+1}</td>
                <td>{data.documentTitle} </td>
                <td>{fetchField(data.documentType, documents, 'documentName')} </td>
                <td><a href={ImagesUrl+'/students/documents/'+data.fileUrl} target="_new" className="btn btn-success btn-sm"     title="View"> <i className="fa fa-download"></i></a> </td>
            </tr>
            )}
                </tbody>

                </table>


     </div>
            </div>
        </div>



        </div> 
                </div>
                </div>




            </div> )}
                </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>
</Template> 
 );
        
}
export default React.memo(StudentProfile) 