import React, { useEffect } from 'react'
 import Cookies from 'js-cookie'
 
const LogOut =()=> {

    
const clearCookies=()=>{
	Cookies.remove('gaatclkysd')	
 Cookies.remove('gaatsckayd')

	Cookies.remove('gaattkauscd')	
 Cookies.remove('gaattkamtk')
 Cookies.remove('gaattkarfsh')
 Cookies.remove('gaattkstnm')

 Cookies.remove('_gaattexpt')
 Cookies.remove('_gaattbexpt')

}

 useEffect(()=>{  
  clearCookies()
  window.open("/", '_self')
},[]); 

  return (
    <div>...please wait</div>
  )
}

export default LogOut