import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {Currency, FormatNumber, ServerUrl,  Token, staffCode, config} from '../component/include'
import Select from 'react-select'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { longDate } from '../component/globalFunction'

const BookIssueReport =()=> {
    const [errors, setErrors] = useState({});
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    
    const [bookIssued, setBookIssued]= useState([])
    const [list, setList] = useState({
        dataType:[],
        from:'',
        to:'',
        returnDate:''
    })
    



    const handleSelect = (option, action)=>{
        setList({...list, [action.name]: option});
        setErrors({...errors, [action.name]:''})
         }
  
         const handleChange = event =>{
           const {name, value} = event.target
           setList({...list, [name]: value});
           setErrors({...errors, [name]:''})
       }



const fetchBookIssueReport =()=>{
             

        setNotice({...notice, 
            isDataFetching: true
        });

let sql = "Select b.bookName, b.authorName, s.staffName, i.bookCode, i.fine, i.userType, s.fileNo, s.mobile, i.ID, i.code,  i.dateIssue, i.issuedCount, i.dateReturn, i.userCode,   i.remarks,   i.returnedDate, i.returnStatus from tbl_book_issued i, tbl_staff s, tbl_books b where i.bookCode = b.code and i.userCode = s.code and i.usertype='Staff' and i.userCode ='"+staffCode+"' "



if(list.dataType.length !==0 ){
      sql = sql + " and i.returnStatus ='"+list.dataType.value+"'"
  }


  if(list.from !== '' && list.to !== ''){
    sql = sql + " and i.dateIssue BETWEEN '"+list.from+"' and '"+list.to+"'"
}
const fd = new FormData();
fd.append('jwt', Token);
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{ 
    setBookIssued(result.data)
    setNotice({...notice, 
        isDataFetching: false
    });

    window.scrollTo(0,document.body.scrollHeight);

})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})

    

}

   
	
let i =1;
const tableHeader = [
{dataField: 'code', text: 'SNo', footer:'SNo', formatter:()=>i++, csvExport: false},
        {dataField: 'staffName', footer:'Issued To', text: 'Issued To', formatter: (cell, row)=>` ${cell}  `},
        {dataField: 'bookName', text: 'Books', footer:'Book', editable:false, formatter: (cell, row)=>row.returnStatus==='Yes'?<div style={{display:'block',  width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{`${cell} (${row.authorName}) `}<br/><span className="pcoded-badge label label-success">Returned on {longDate(row.returnedDate)}</span></div>: <div  style={{display:'block',  width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell} ({row.authorName}) </div>},       
        {dataField: 'dateIssue', text: 'Date of Issue', footer:'Date of Issue', formatter:(cell,row)=>
        longDate(cell)},
    {dataField: 'dateReturn', text: 'Date to Return', footer:'Date to Return',  formatter:(cell,row)=>
    longDate(cell)}, 
    {dataField: 'fine', text: 'Fine', formatter:(cell,row)=>Currency+FormatNumber(cell), footer:columnData => Currency+FormatNumber(columnData.reduce((acc, item) => Number(acc) + Number(item), 0))}
 ];
 

 
 const BookTable=(props)=>{

    const CustomToggleList = ({
        columns,
        onColumnToggle,
        toggles
      }) => ( 
      <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
          {
            columns
              .map(column => ({
                ...column,
                toggle: toggles[column.dataField]
              }))
              .map(column => (
                
                <button
                  type="button"
                  key={ column.dataField }
                  className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                  data-toggle="button"
                  aria-pressed={ column.toggle ? 'true' : 'false' }
                  onClick={ () => onColumnToggle(column.dataField) }
                >
                  { column.text }
                </button>
              ))
          }
        </div>
        
      );

    
     const { SearchBar } = Search;
 
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
         showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;
                           
                             <div className="btn-group dropdown-split-success">
  
  <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <i className="fa fa-search-plus"></i> Columns
  </button>
  
  <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    <CustomToggleList { ...props.columnToggleProps } />
  </div>
  </div>

  {/* <div className="dt-buttons btn-group pull-right"> 	
<div className="btn-group dropdown-split-primary ">
    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className="fa fa-download"></i>Export
    </button>
    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    <form method='POST' action={ServerUrl+'/excel_controller.php?api=issueStaff'} target='_blank'>

<input type='hidden' name='jwt' value ={Token} />

<button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>

</form>

<form method='POST' action={ServerUrl+'/printout/books_issue_staff.php?token='+userToken} target='_blank'>

<input type='hidden' name='jwtToken' value ={Token} />
<button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>

</form>
    </div>
</div> 
</div>  */}
  
                             </div>
                             
                      
             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }



useEffect(()=>{
    fetchBookIssueReport()
},[]);



        return (  
            <React.Fragment>
   <Template menu="Library" submenu='Library Due'>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Library">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Library</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Books Issue Report</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
               
                <div className="row  ">
							

	<div className="col-sm-12">
    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>  Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        
        <div className="row">
								
            <section className="col-md-4">

            <div className="form-group">
						<label >Type</label>
                        <Select  options={[
                    { value: 'Yes', label: 'Already Returned' },
                    { value: 'No', label: 'Not Yet Return' },
                ]} 
        onChange={handleSelect}  name="dataType" value={list.dataType}  /> 
			</div>
</section>

<section className="col-md-4">
<div className="form-group">
						<label >From Date</label>
		<input type="date" name="from" className='form-control'   value={list.from}  onChange={handleChange}  /> 
			</div>
</section>


<section className="col-md-4">
<div className="form-group">
						<label >To Date</label>
		
                        <input type="date" name="to" className='form-control'  value={list.to}  onChange={handleChange}  /> 	
			</div>
</section>


</div>

	<footer className="pull-right">
	<button type="button" id="submit" onClick={fetchBookIssueReport} className="btn btn-inverse "><i className="fa fa-search"></i> Proceed</button>
								</footer>				
							</div>
                            </div>
							</div>
                            </div>   

</div></div>
	
{bookIssued.length!==0?
<div className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Books</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <BookTable data={bookIssued}  columns={tableHeader}  /> 
        } 

            </div></div></div>

    
    </div>
    </div>:''}




   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
</React.Fragment>
 );
        
}

export default React.memo(BookIssueReport) 