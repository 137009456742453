import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import { ServerUrl,  Token,  config, staffCode, ImagesUrl,  staffName, schoolName} from '../component/include'

import {  getDays, getWeekNumber, getTime, longDate, getClassName} from '../component/globalFunction'

const MySchedule =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const structure = useRef(null)
    const [schoolInfo, setSchoolInfo]=useState([])
    
    const [staff, setStaff]= useState([])
    const [schedule, setSchedule]=useState({
        staff:[],
        weekDate:''
    });

    const [CurrentSession, setCurrentSession] = useState('')
    const [schoolClass, setSchoolClass]=useState([]);
    const [subject, setSubject]= useState([]) 
    const [scheduleList, setScheduleList] = useState([]);
    const [periodList, setPeriodList] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
   
    const  fetchStaff =()=>{  
        
        const fd = new FormData();
            fd.append('jwt', Token);
   let sql = "Select code, staffName, fileNo from tbl_staff where code ='"+staffCode+"'"
       let url = ServerUrl+'/fetchBySql_controller'
       fd.append("sql", sql)
       axios.post(url, fd, config).then(result =>setStaff(result.data)) 
   
    }    
    
    const fetchSchoolInfo =()=>{
        const fd = new FormData();
            fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }
    const fetchSchoolClass =()=>{
        const fd = new FormData();
            fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 

    }
const getToday =()=>{
        var date = new Date();
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        
        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        
        var today = year + "-" + month + "-" + day; 
    
   setSchedule({...schedule,
        weekDate:today
        })
    }

    const fetchSubject =()=>{
        const fd = new FormData();
            fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }
    
    const handleChange=(event)=>{
        const {name, value} = event.target
        setSchedule({...schedule, [name]:value});
        setErrors({...errors, [name]:''})
        setScheduleList('')
        setPeriodList('')
    }

    const handleSelect = (option, action)=>{
        setSchedule({...schedule, [action.name]: option});
        setErrors({...errors, [action.name]:'', scheduleList:''}) 
        setScheduleList('')
        setPeriodList('')
    }

    const  fetchTableList =()=>{  
 if(!schedule.weekDate){
            setErrors({...errors, weekDate:'please select week date'})
        }else{

        

        var date = new Date(schedule.weekDate)
            const day = 1000 * 60 * 60 * 24
            var answer = []
            for(var i =0; i<7; i++){
                answer.push(date.toISOString().slice(0,10))
                date.setTime(date.getTime()+day)
            }

            const fd = new FormData();
            fd.append('jwt', Token);
    
            var value = [];
            value.push({code:Math.random().toString(36).substr(2, 9), periodCode:'',  subject:'', sectionID:'', classID:'', timeList:[]})
    
            let sql = "Select code,  periodList, weekDate from tbl_staff_schedule where staffCode = '"+staffCode+"' and weekDate IN("+answer.map(data=>'"'+data+'"')+")"
     
          setNotice({...notice, 
            isLoading: true})
          let url = ServerUrl+'/fetchBySql_controller'
           fd.append("sql", sql)
          
           axios.post(url, fd, config).then(result =>{
           
             if(result.data.length!==0){
                setScheduleList(result.data)
                let period = result.data.filter(dat=>dat.weekDate===schedule.weekDate)
                 var final = period.length!==0?JSON.parse(period[0].periodList):value
                setPeriodList(final)
                }else{
                   setScheduleList('')
                   setPeriodList(value)
                } 
        
            }).finally(()=>{
                setNotice({...notice, 
                    isLoading: false})
            })  
    
        } 

    }
        const createNewRow =()=>{
            setPeriodList(periodList.concat({code:Math.random().toString(36).substr(2, 9), periodCode:'',  subject:'', sectionID:'', classID:'', timeList:[]}))
            setErrors({...errors, scheduleList:''})
        }
    
        const removeRow=(index)=>{
            setPeriodList(periodList.filter((_, i) => i !== index)); 
           }

           const handleChangeClass = (e, index)=>{
            const prd =JSON.parse(JSON.stringify(periodList)) 
            const {name, value}= e.target 
            prd[index][name] = value;

            if(document.getElementById(value)){
                prd[index].classID = document.getElementById(value).getAttribute("classID")
            }
            
            let sql = "Select periodList from tbl_timetable where sectionID ='"+value+"' and day ='"+getDays(schedule.weekDate)+"' and sessionYear ='"+CurrentSession+"'"
     
    let url = ServerUrl+'/fetchBySql_controller'
    const fd = new FormData();
            fd.append('jwt', Token);
        fd.append("sql", sql)
     axios.post(url, fd, config).then(result =>{
        if(result.data.length!==0){
            var answer = result.data[0].periodList
            prd[index].timeList = JSON.parse(answer)
            setPeriodList(prd)
        }else{
            prd[index].timeList = []
            setPeriodList(prd)
        }
        
     })
             
        }


           const handleChangeSchedule = (e, index)=>{
            const std =JSON.parse(JSON.stringify(periodList)) 
            const {name, value}= e.target  
               std[index][name] = value;
            setPeriodList(std);
            
        }




const handleSubmit = event =>{
    event.preventDefault();    
    setErrors(ValidateErrors(schedule));
    setIsSubmitting(true);
}  
const ValidateErrors=(values)=>{
    let errors = {}; 
        if(periodList.length===0){
            errors.staff ='There must be at least one schedule define';
        } 
        
        if(!values.weekDate){
            errors.weekDate ='Please select date';
        }
    return errors;
    }


useEffect(()=>{
if(Object.keys(errors).length === 0 && isSubmitting){
submit()
}
},[errors])



const  submit=()=>{  
          
setNotice({...notice, 
    isLoading: true})   

    const fd = new FormData();
            fd.append('jwt', Token);
            fd.append('staffCode',  staffCode)
            fd.append('weekDate',  schedule.weekDate)    
        fd.append('weekNumber', new Date(schedule.weekDate).getFullYear()+'/'+getWeekNumber(schedule.weekDate)) 
            fd.append('periodList',  JSON.stringify(periodList, null, 2))

 let url = ServerUrl+'/save_controller/tbl_staff_schedule'          
         
    axios.post(url, fd, config)
    .then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
            
                }else if(response.data.type ==='info'){
                    Alerts('Update!', 'default', response.data.message)
                    
                        }else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            fetchTableList()
    setIsSubmitting(false)
    
		structure.current.scrollIntoView({ behavior: "smooth" });
    setNotice({...notice, 
        isLoading: false})
        })

 } 

 const getSubject =(code)=>{
    const result = subject&&subject.filter(list=>list.code===code);
    const answer = result.map(c=>c.subjectName);
    return  answer  
}



const getTableDetails=(data)=>{
    const getRecords=(code, list)=>{
        const answer = list.filter(item =>String(item.periodName)===String(code))
        const result = answer.length!==0?<strong className="text-green">{getTime(answer[0].startTime)+ ' ~ '+getTime(answer[0].endTime)} </strong>:''
        return result
    }

    const list = JSON.parse(data)
    return list.map((item, id)=>
    <div key={id} className="attachment-block text-center">  
<strong className="text-green"> {getClassName(item.classID, item.sectionID, schoolClass)}</strong>
<br/>
{getRecords(item.periodCode, item.timeList)}
<br/>
    <b className="text-green">{getSubject(item.subject)}</b><br/>
      
        
        <br/>
    </div>
    )     
}


   


const  handleSession =()=>{

    var sql ="Select  startYear from tbl_school_session where status ='Active'"
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config)
    .then(result => {
        if(result.data.length!==0){
        setCurrentSession(result.data[0].startYear)
    }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    }

useEffect(()=>{
    handleSession()
    fetchSubject()
    fetchStaff()    
    fetchSchoolClass()
    fetchSchoolInfo()
getToday()
},[]);



        return (  
            <React.Fragment>
   <Template menu="Timetable" submenu='Staff Schedule'>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Schedule">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Staff Schedule</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  no-print">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Staff Schedule</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
           
           
           
        <div className="card z-depth-0" >
		<div  className="card-block">
            <div className="row  ">
		

<section className="col-md-12">
<div className="form-group">
<label >Date</label>
<input type="date" className={errors.weekDate ? 'form-control form-control-danger' : 'form-control'} name="weekDate" id="weekDate"  onChange={handleChange} value={schedule.weekDate} />
<span style={{color:'red'}}>{errors.weekDate && errors.weekDate}</span>
   </div>

</section>
	</div>
    <footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchTableList}  className="btn btn-primary "><i className="fa fa-search"></i> Search</button>
    </footer>			
</div>
</div>  


{periodList.length!==0?
<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
        <div className="table-responsive">
         <table className="table">
            <thead>
                <tr>
    <th><a onClick={createNewRow} href="#!"><i className="fa fa-plus"></i> Add</a></th>
                    <th>Class </th>
                    <th>Period </th>
                    <th>Subject</th>
                    
                </tr>
            </thead>
            <tbody>
           
                 {periodList&&periodList.map((pl, id)=>
            <tr key={id}>
                <td> <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>
                <td>
   <select onChange={(e)=>handleChangeClass(e, id)} id={'class'+id}  name="sectionID" classID={pl.sectionID}  value={pl.sectionID} className="form-control">

                    <option value="">Select</option>
                    { schoolClass&&schoolClass.map((list, idx)=> {
                            return <optgroup key={idx} label={list.cname}>
                                {list.section.map((sec, id)=>{
                                    return sec.sname !==''?<option classID={list.ID} id={sec.ID} issection='1' key={id} value={sec.ID} >{list.subjectName} {sec.sname}</option>:
                                <option key={id} id={list.ID} value={list.ID} classID={list.ID} issection='0' >{list.cname}</option>
                                })}

                            </optgroup>
                    })}
                        </select> 
                        
                        
                        </td>
                    <td>

                    <select  onChange={(e)=>handleChangeSchedule(e, id)}  name="periodCode" code={pl.periodCode}  value={pl.periodCode} className="form-control">

                    <option value="">Select Period</option>
                     {pl.timeList.length!==0?pl.timeList.map((lst, idx)=> {
                               return  <option key={idx}  value={lst.periodName} >{getTime(lst.startTime)} ~ {getTime(lst.endTime)}</option> }):[]}  
                        </select>


                    </td>
                    <td>
                    <select onChange={(e)=>handleChangeSchedule(e, id)}  name="subject"  className="form-control" value={pl.subject} >

                    <option value="">Select</option>
                   { subject&&subject.map((list, idx)=> {
                               return  <option key={idx} value={list.code} >{list.subjectName} ({list.abbreviation})</option> })}
                        </select>
                    

                    </td>
                    
                   
                </tr>)} 
                
            </tbody> 
            <tfoot><tr>
                <td colSpan='4'>
                <footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-inverse "><i className="fa fa-save"></i> Save</button>
								</footer>
                </td></tr>
            </tfoot>
        </table> 

        </div>
        </div>
        </div>
</div>:''}

     
     </div>  </div>
							
                            </div>
                            
                            </div>





                           

                            {scheduleList.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
                <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td >
<h4 style={{textAlign:'center'}}><b>{schoolName}</b></h4>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}>  {st.mobile} &nbsp; {st.altMobile} </div>
 <div style={{textAlign:'center'}}>  {st.email} </div></td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>STAFF WEEKLY SCHEDULE </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Staff: <b>{staffName}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
      
       <table className="table table-bordered">
            <thead>
                <tr>
                    <th>Monday</th>
                    <th>Tuesday</th>
                    <th>Wednessday</th>
                    <th>Thursday</th>
                    <th>Friday</th>
                    <th>Saturday</th>
                    <th>Sunday</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                    {scheduleList&&scheduleList.map((list, index)=>getDays(list.weekDate)==='Monday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>getDays(list.weekDate)==='Tuesday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>getDays(list.weekDate)==='Wednessday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>getDays(list.weekDate)==='Thursday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>getDays(list.weekDate)==='Friday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>getDays(list.weekDate)==='Saturday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>getDays(list.weekDate)==='Sunday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.periodList)}</span>:' ')}
                        </td>

                </tr>
            </tbody> 
        </table>

 </div>	 </div> 


 </div>
        </div>:''}

        <div ref={structure} />
   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
</React.Fragment>
 );
        
}

export default React.memo(MySchedule) 