import React, {useState, useEffect} from 'react'
import {useLocation, useHistory} from 'react-router'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, ImagesUrl, Token, staffCode, config, staffName} from '../component/include'
import { userToken } from '../component/authentication'

const StaffProfile =()=>{
    let history = useHistory();
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    const [staff, setStaff] = useState([]);
const [position, setPosition]=useState([])
const [department, setDepartment]=useState([])

const [content, setContent] = useState({
    staffCode:staffCode,
    imageUrl:'',
    passport:'',
    staffName:staffName
})  

const fetchDepartment =()=>{
    const fd = new FormData();
                fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_staff_settings/department/ID/1'
    axios.post(url, fd, config)
    .then(result =>setDepartment(result.data))
}
const fetchPosition =()=>{
    const fd = new FormData();
                fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_staff_settings/position/ID/1'
    axios.post(url, fd, config)
    .then(result =>setPosition(result.data))
}
const getPosition =(code)=>{
const answer =  position.filter(item=>item.code===code)
const reply = answer.length!==0?answer[0].positionName:''
return reply
}


const getDepartment =(code)=>{
const result = department.filter(item=>item.code===code);
const answer = result.length!==0?result[0].departmentName:''
return  answer
}

   
    const handleFetchStaff =()=>{
        setNotice({...notice, 
            isLoading: true})
        var sql ="Select *  from tbl_staff where code ='"+staffCode+"' "; 
        const fd = new FormData();
        fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setStaff(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isLoading: false})
    })
    }
    const  handleFile = function(fieldName){
        return function(newValue){                 
            const value = newValue.target.files[0]
          // setImage(value)
       readURL(newValue);
       }
       
      } 
   

    const readURL = (input)=>{
       
        let displayMessage = '';
        const PreviewImage = input.target.name;
            var fileName = document.getElementById(PreviewImage).value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png"  || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 1) {
            displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 1 Mb';
            document.getElementById(PreviewImage).value = '';
           setContent({...content, imageUrl:'',  passport:''})
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.
                var reader = new FileReader();//Initialize FileReader.
                reader.onload = function (e) {
                  
                  setContent({...content, passport:e.target.result, imageUrl:input.target.files[0]})
                    
                }
                reader.readAsDataURL(input.target.files[0]);
                
            }
        }
    }else{
        displayMessage = 'Only jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 1 Mb'
       document.getElementById(PreviewImage).value = '';
       setContent({...content, imageUrl:'',  passport:''})
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
        
    } 


    
   function updatePassport(){  

    if(content.imageUrl===''){
      Alerts('Error!', 'danger', 'Kindly upload your passport')
    }else{
              setNotice({...notice,  isLoading: true})     
            
                let formData = content;
                const fd = new FormData();
                fd.append('jwt', Token);
                for(let k in formData){
                fd.append(k, formData[k])
                }
                 let url = ServerUrl+'/save_controller/tbl_teacher_passport'
                axios.post(url, fd, config)
                .then(response =>{
                 if(response.data.type ==='success'){
                  Alerts('Saved!', 'success', response.data.message)
                    } else{
                  Alerts('Error!', 'danger', response.data)
                    }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                  setNotice({...notice, 
                    isLoading: false
                  })
                  setContent({...content, imageUrl:''})
                //  window.location.reload()
                }) 
            }
          }




useEffect(()=>{   
    fetchDepartment();
    fetchPosition();
    handleFetchStaff()
},[]);

        return (  
            <React.Fragment>
   <Template menu='Dashboard' submenu=''>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper"> 

        <PageHeader title="Staff">
        <li className="breadcrumb-item"><a  href="#!"> <i className="feather icon-home"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">Staff</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Profile</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div>






                {/* <!-- Edit student start here  --> */}

            {staff.length!==0?    <div className="col-md-12">
                <div className="card z-depth-0">
        
        <div className="card-block">


    <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                                    
    {staff&&staff.map((item, idx)=>
        <div key={idx} className="card">
        <div className="row">
        <div className="col-lg-12">
            <div className="cover-profile">
                <div className="profile-bg-img">
            <img className="profile-bg-img img-fluid" src={ImagesUrl+'/bg.jpg'} style={{display:'block', height:'200px',width:'1000px'}} alt="bg-img"/>
                    <div className="card-block user-info">
                        <div className="col-md-12">
                            <div className="media-left">
                                <a href="#!" className="profile-image">

                            {item.passport!==''?content.passport===''?
            
                <img id="viewPassport2" className="user-img img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/staff/'+item.gender+'.png'}} src={`${ImagesUrl}/staff/${item.passport}`} alt="user-img" style={{display:'block', height:'150px',width:'150px'}} /> :

                <img id="viewPassport" className="user-img img-radius"  src={content.passport} style={{display:'block', height:'150px',width:'150px'}} /> :  

                 <img id="viewPassport" className="user-img img-radius" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/staff/'+item.gender+'.png'}} src={content.passport} style={{display:'block', height:'150px',width:'150px'}}/>      }
                     </a> </div>
                            <div className="media-body row">
                                <div className="col-lg-12">
                                    <div className="user-title">
                                        <h2>{item.staffName}</h2>
                                        <h2 >{getDepartment(item.department)}</h2>

                                        
                                    </div>
                                    
                                    </div>
                                    <div>  

                    <input id="passport"  onChange={handleFile('passport')} className="form-control file" name="passport" type="file" accept="image/*" /> 

                                 {content.imageUrl===''?   <label  htmlFor="passport" className='btn btn-warning btn-sm'><i className="fa fa-upload"></i> Change Passport</label>:

                                    <button type='button' onClick={updatePassport} className='btn btn-success btn-sm'><i className="fa fa-image"></i> Save Passport</button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div className="col-sm-12">
<div className="row ">
<div className="col-sm-1"></div>
    <div className="col-sm-7">
    <br/>
                 
        <ul className="list-unstyled">
            <li> <span className="txt-color-darken"><b>Birth Date</b>:&nbsp;{item.birthDate}</span></li>
            <li>  <span className="txt-color-darken"><b>Date Of Joining</b>:&nbsp;{item.joinDate}</span></li>
            <li><span className="txt-color-darken"><b>Father Name </b>:&nbsp;{item.fatherName}</span></li>
            <li><span className="txt-color-darken"><b>Mother Name </b>:&nbsp;{item.motherName}</span></li>
            <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Position </b>:-&nbsp;{getPosition(item.position)}</span> </p> </li>
                    <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Department </b>:-&nbsp;{getDepartment(item.department)}</span> </p> </li>
        </ul>
        <br/>                                           
        <p className="font-md" ><i >Present Address</i></p><p> {item.presentAddress}</p>
            
        <p className="font-md" ><i >Permanent Address</i></p><p> {item.permanentAddress}</p>

    <p className="font-md" ><i >Status</i></p><p>{item.status==='true'? <span className="pcoded-badge label label-success">ACTIVE</span>: <span className="pcoded-badge label label-danger">INACTIVE</span>}</p>
            
    </div>
    <div className="col-sm-4">
        <h1><small >Contact Info</small></h1>
    <ul className="list-unstyled">
        <li> <p className="text-muted">
                <i className="fa  fa-mobile"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.mobile}</span>
            </p> </li>
        <li><p className="text-muted">
                <span className="txt-color-darken" style={{whiteSpace: 'normal', wordBreak: 'break-word'}}>
                    <i className="fa fa-envelope"></i>&nbsp;&nbsp;{item.email}                                                  </span></p>
        </li>
        <li><p className="text-muted"><i className="fa fa-mobile-phone"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.altMobile}</span></p></li>
        <li><p className="text-muted"><i className="fa fa-at"></i>&nbsp;&nbsp;
                <span className="txt-color-darken">{item.email}</span></p></li>
       
        <li><p className="text-muted">
                    <span className="txt-color-darken"><b>Username </b>:-&nbsp;{item.username}</span> </p> </li>
       
    </ul>
                        </div>
                    </div>
                </div>
            </div>
            )}
            
            
            </div>
    </div></div>
    
<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<form method='POST' action={ServerUrl+'/printout/staff_profile.php?token='+userToken} target='_blank'>
                <input type='hidden' name='staffCode' value ={staffCode} />
                <input type='hidden' name='jwtToken' value ={Token} />
                
        <button type="submit" className="btn btn-inverse  no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
								</footer>		</section>		
							</div>
    </div> </div>
            </div> :''}
                </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>
</Template> 
</React.Fragment>
 );
        
}
export default React.memo(StaffProfile) 