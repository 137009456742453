import React, {useEffect} from 'react';
import {Alerts} from './component/notify'
import 'react-notifications-component/dist/theme.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';


import ViewStudents from './students/view_students'
import StudentProfile from './students/profile'
import StudentReview from './students/student_review'





import result_comment from './exam/result_comment';
import print_mid_term_result from './exam/print_mid_term_result';
import print_class_result from './exam/print_class_result';
import Transcript from './exam/transcript';


import ResultEntry from './exam/result_entry'
import AssessmentEntry from './exam/assessment_entry'
import SubjectMark from './exam/subject_mark'
import ClassMark from './exam/class_mark'
import ExamSchedule from './exam/exam_schedule'

import Half_term_result_entry from './exam/half_term_result_entry';
import mock_result_entry from './exam/mock_result_entry';





import DisplayStaffAttendance from './attendance/display_staff_attendance'
import StudentAttendance from './attendance/student_attendance'
import DisplayStudentAttendance from './attendance/display_student_attendance'
import StudentLateArrival from './attendance/student_arrival_departure_late'
import StudentAttendanceSummary from './attendance/student_attendance_summary'
import StaffAttendanceSummary from './attendance/staff_attendance_summary'
import StaffWorkinHours from './attendance/staff_working_hours'


import eLibrary from './library/e-library'
import BookReturn from './library/issues_return'
import Books from './library/books'






import Setup from './timetable/setup'
import HolidaysList from './timetable/holidays_list'
import LessonPlan from './timetable/lesson_plan'
import TimetableByDate from './timetable/timetableByDate'
import TimetableByDay from './timetable/timetableByDay'
import ViewTimetable from './timetable/view_timetable'
import StaffSchedule from './timetable/staff_schedule'

import Transfer from './students/transfer_class'
import Promotion from './students/promotion'

import Profile from './staff/profile'
import Review from './staff/review'
import Noticeboard from './staff/noticeboard'
import Signature from './staff/signature'
import ChangePassword from './staff/change_password'


import Dashboard from './dashboard/dashboard'
import Error404 from './settings/error404';


import axios from 'axios'
import { SessionTimeout } from './component/notify'
import Cookies from 'js-cookie'
import {classID, config, sectionID, ServerUrl, staffCode, staffName, Token} from './component/include'
import {encrypt} from './component/authentication'
import upcoming_birthday from './students/upcoming_birthday';
import class_session_cumulative from './exam/class_session_cumulative';



const ProtectedView =()=>{

const openWarningModal =(interver)=>{
      let openWarning =   document.getElementById('openTimeoutModal')
              openWarning.click();
              clearInterval(interver)
}
 

const handleReconnect =()=>{

      let closeWarning =   document.getElementById('btnLogoutsession')
              closeWarning.click();

           
const fd = new FormData();

fd.append('code', staffCode)
  fd.append('staffName', staffName)
  fd.append('classID', classID)
  fd.append('sectionID', sectionID)
fd.append('jwt', Token)


    let url = ServerUrl+'/login_refresh_controller.php?tablename=tbl_refresh_staff_login';
		axios.post(url, fd, config)
		.then(response =>{
	if (response.data.type ==='success'){

          //1000 * 60 * SESSION_IDEL_MINUTES
          var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
			var inOneMinutes = Date.now() + 418 * 60 * 1000;
			  var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
			  
		
        Cookies.set('_gaattexpt', inSixtyMinutes, {expires: inOneHours  })
        Cookies.set('_gaattbexpt', inOneMinutes, {expires: inOneHours})

  Cookies.set('gaattkauscd', encrypt(response.data.code), {expires: inOneHours })
  Cookies.set('gaattkamtk', encrypt(response.data.jwt),  {expires: inOneHours })   
  Cookies.set('gaattkarfsh', encrypt(response.data.userToken),  {expires: inOneHours })
  Cookies.set('gaattkstnm', encrypt(response.data.staffName),  {expires: inOneHours })


  Cookies.set('gaatclkysd', encrypt(response.data.classID),  {expires: inOneHours })
  Cookies.set('gaatsckayd', encrypt(response.data.sectionID),  {expires: inOneHours })

      Alerts('Info!', 'success', 'Successfuly reconnected')

        window.location.reload()
             } else {
				handleLogout()
			}  
       

		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
     handleLogout()
		})
}



const handleLogout =()=>{
Alerts('Info!', 'default', 'Your session has expired')
window.location.href='/logout'
}


const startWarning = ()=>{
  var interver;
  interver = setInterval(() => {
    //one minute before expiry time
 var beforeExpired = Number(Cookies.get('_gaattbexpt'))
  if(beforeExpired < Date.now()){
    openWarningModal(interver)
//timeout in one minutes time
  }
}, 5000)
}
  
     
useEffect(()=>{
  if (localStorage.getItem("gaattlcksr") !== null) {
    document.getElementById('openlockscreenModal').click()
 }
})


useEffect(()=>{
  //fetchContent()
startWarning()
   
   setInterval(() => {
 var expiredTime = Number(Cookies.get('_gaattexpt'))
  if(expiredTime < Date.now()){
    handleLogout()
    //timeup logout user
  }
}, 5000)
}, [])

    return ( <>
    <a href="#!" data-toggle="modal" id='openTimeoutModal' data-target='#timeoutModal' ></a>
    <SessionTimeout  Logout={handleLogout} Connected ={handleReconnect} /> 
    
    <Router  >
      <Switch>


      <Route exact path="/dashboard" component={Dashboard} />


    {/* <Route exact path="/students/registration" component={StudentAdmission} /> */}

    <Route exact path="/student/profile/:code" component={StudentProfile} /> 
 <Route exact path="/students/view_students" component={ViewStudents} />
 <Route exact path="/students/review" component={StudentReview} />
 <Route exact path="/students/transfer_class" component={Transfer} />
 <Route exact path="/students/promotion" component={Promotion} />

 <Route exact path="/students/birthdays" component={upcoming_birthday} />
 {/* 

 <Route exact path="/students/define_family" component={family_summary} />

 <Route exact path="/conference" component={Conference} />
      <Route exact path="/fees/class_fees" component={Class_fee} />
      <Route exact path="/fees/update_student_fees" component={UpdateStudentFees} /> 
        <Route exact path="/fees/fees_payment" component={FeesPayment} />
        <Route exact path="/fees/print_voucher" component={PrintVoucher} />
        <Route exact path="/fees/payment_history" component={PaymentHistory} />
        <Route exact path="/fees/date_wise_report" component={DateWiseReport} />
        <Route exact path="/fees/student_fees" component={StudentFees} />
        <Route exact path="/fees/fees_register" component={FeeRegister} />
 <Route exact path="/exam/view_result" component={ViewResult} /> 
 <Route exact path="/exam/mid_term_result" component={MidTerm} />
*/}

        <Route exact path="/exam/mock_result_entry" component={mock_result_entry} />
        <Route exact path="/exam/result_entry" component={ResultEntry} />
        <Route exact path="/exam/assesment_entry" component={AssessmentEntry} />  
        <Route exact path="/exam/subject_marks" component={SubjectMark} />
        <Route exact path="/exam/result_comment" component={result_comment} />

        <Route exact path="/exam/exam_schedule" component={ExamSchedule} />
        <Route exact path="/exam/class_marks" component={ClassMark} />
        <Route exact path="/exam/class_cummulative_result" component={class_session_cumulative} />
        

        <Route exact path="/exam/view_result" component={print_class_result} />  

        <Route exact path="/exam/cat_scores_entry"  render={(props) => <Half_term_result_entry menu="Dashboard" {...props } />} /> 
        <Route exact path="/exam/student_transcript" component={Transcript} /> 

         <Route exact path="/exam/mid_term_result" component={print_mid_term_result} /> 



        <Route exact path="/attendance/display_staff_attendance" component={DisplayStaffAttendance} />
        <Route exact path="/attendance/student_attendance" component={StudentAttendance} />
        <Route exact path="/attendance/display_student_attendance" component={DisplayStudentAttendance} />
        <Route exact path="/attendance/student_arrival_departure" component={StudentLateArrival} />
        <Route exact path="/attendance/student_attendance_summary" component={StudentAttendanceSummary} />
        <Route exact path="/attendance/staff_attendance_summary" component={StaffAttendanceSummary} />
        <Route exact path="/attendance/staff_working_hours" component={StaffWorkinHours} />



        <Route exact path="/library/elibrary" component={eLibrary} /> 
<Route exact path="/library/books_report" component={BookReturn} /> 
<Route exact path="/library/books" component={Books} />

{/* 
        <Route exact path="/payroll/salary_structure" component={SalaryStructure} />  
       <Route exact path="/payroll/apply_leave" component={ApplyLeave} />                  
       <Route exact path="/payroll/payslip" component={Payslip} /> 
       <Route exact path="/payroll/staff_salary" component={StaffSalary} />  */}


     


        <Route exact path="/timetable/setup" component={Setup} />
        <Route exact path="/timetable/setup_class_timetable" component={TimetableByDay} />  
        <Route exact path="/timetable/class_lesson_plan" component={TimetableByDate} />   
        
        <Route exact path="/timetable/lesson_plan" component={LessonPlan} />    
       <Route exact path="/timetable/holidays_list" component={HolidaysList} /> 
       <Route exact path="/timetable/view_timetable" component={ViewTimetable} /> 
       <Route exact path="/timetable/staff_schedule" component={StaffSchedule} /> 
        



      <Route exact path="/staff/review" component={Review} /> 
      <Route exact path="/staff/noticeboard" component={Noticeboard} /> 
      <Route exact path="/staff/change_password" component={ChangePassword} /> 
      <Route exact path="/staff/signature" component={Signature} /> 
      <Route exact path="/staff/profile" component={Profile} /> 


        <Route exact  component={Error404} />
      </Switch>
      </Router>
      </>
    );
  
}
export default ProtectedView;
