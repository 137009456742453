import React, {useState, useEffect, useCallback, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, sectionID, classID} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getClassName} from '../component/globalFunction'



const Promotion =()=>{
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    
const [promotion, setPromotion] = useState({
    fromClass:[],
    toClass:[]
})

const [students, setStudents] = useState([]);  
const [isSubmitting, setIsSubmitting] = useState(false);
    const [schoolClass, setSchoolClass]=useState([]);
    


    const  handleFetchClass =()=>{
        const fd = new FormData();
    fd.append("jwt", Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1';
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 
            }
        
      
        
  
    const steps = [{title: 'Class'}, {title: 'Promotion '}]

    const [activeStep, setActiveStep] = useState(1);

    const handleOnClickStepper = (step) => {
        setActiveStep(step);   
        window.scrollTo(0,0);
    }
    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
        window.scrollTo(0,0);
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
        window.scrollTo(0,0);
       
    }, [activeStep])



     const handleSelectTo = (option, action)=>{	
        setPromotion({...promotion, [action.name]: option});
        setErrors({...errors, [action.name]:'', studentList:''})
     }


    const  handleStudents =()=>{  
        setNotice({...notice,  isLoading: true });
        
        var sql ="Select code, admissionNumber, studentName, registerNumber from tbl_students where admissionStatus = 'Studying' and sectionID ='"+sectionID+"'" ;
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            var options = result.data;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
              value.push({code:options[i].code, studentName:options[i].studentName, registerNumber:options[i].registerNumber, admissionNumber:options[i].admissionNumber, status:false});
            }
            setStudents(value)
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,  isLoading: false });
        })
        
         }


         const handleChange =(index)=>{
            const student =JSON.parse(JSON.stringify(students))   
           const value = !student[index].status 
           student[index].status = value;
           
          setStudents(student); 
                
               }





     const handleSubmit = event =>{
        event.preventDefault();
        setErrors(ValidateInput(promotion));
		setIsSubmitting(true);
	} 

    
const ValidateInput =(values)=>{
	let errors = {};   
		
	 
    if(!values.toClass.value){
        errors.toClass ='Please select to Class';
	}
    

   if(sectionID === values.toClass.value ){
        errors.studentList ='Same class selected, kindly change class to be promoted to ';
    }
   

    if(students.filter(item=>item.status===true).length===0){
        errors.studentList ='Please tick box of  at least one student to promote';
    }

     if(students.length===0){
        errors.studentList ='There are no student in the selected class';
    }
    
    
    return errors;
	}
	
	

function submit(){ 
    
      const fd = new FormData(); 
    let promoted = students.filter(list=>list.status===true);
       fd.append('classId', promotion.toClass.classID);
        fd.append('sectionId', promotion.toClass.value);
        fd.append('studentList', JSON.stringify(promoted.filter(lst=>lst.code), null, 2));
        fd.append("jwt", Token);
    setNotice({...notice, isLoading: true}) 
      let url = ServerUrl+'/update_controller/tbl_promote_students';
	  axios.post(url, fd, config)
	  .then(response =>{
		if (response.data.type ==='success'){
			Alerts('Saved!', 'success', response.data.message)
		} else{
			Alerts('Error!', 'danger', JSON.stringify(response.data))
	}  
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', JSON.stringify(error.message)) 
	})
	.finally(()=>{
	  setNotice({...notice, isLoading: false}) 
      setIsSubmitting(false);
    setStudents([])
    setPromotion({
        toClass:[],
        fromClass:[]
    })
setActiveStep(1)
    }) 
	 
}

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])



    useEffect(()=>{
        handleFetchClass();
        handleStudents()
    },[]);

        return (  <Template menu="Student" submenu='Promote Student'>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Promotion">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-graduation-cap"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="/students/view_students">Students</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Promotion</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
<div className="page-body">
    
<div className="row">
<div className="col-sm-12">

				
	<div className="card z-depth-0">
	
		<div className="card-header">
			<h5>Student Promotion</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
<div className="container">
  
<div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>

        {activeStep === 1 ?	<div>  <div className="row setup-content" id="class">
        <div className="col-md-12">
                <div className="card z-depth-0">
            <div className="card-block">
                <div className="row">


		  <div className="col-md-12">
		  <div className="form-group">
			  <label className="control-label">From Class</label>

              <input type='text' name='className' readOnly='true' className='form-control' value={getClassName(classID, sectionID, schoolClass)} />
            
			</div>

			<div className="form-group">
			  <label className="control-label">To Class</label>
              <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID,isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
                             })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectTo} className={errors.toClass ? 'form-control form-control-danger' : ''} name="toClass" value={promotion.toClass}  /> <span style={{color:'red'}}>{errors.toClass && errors.toClass}</span>

			</div>
			</div>
			
            </div>
		  </div>
		  
		  </div>

		  </div>
		  
          </div>
            
            </div>
          
          
          :  <div className="row setup-content" id="promotion">
		    
            <div className="col-md-12">
                <div className="card z-depth-0">
            <div className="card-block">
                <div className="row">
                <div className="table-responsive">   
		   <table className="table">
	<thead >
		<tr >
            <th>Register No</th>
		
        <th  >Admission Number</th>
		<th  >Student Name</th>
        <th  >Promote</th>
	</tr>
	</thead>
	<tbody>
    {students&&students.map((st, idx)=> 
		<tr key={idx} >
			<td>{st.registerNumber}</td>
			
				<td> {st.admissionNumber} </td>
                <td> {st.studentName} </td>
                <td>
                        <div className="checkbox-color checkbox-primary">
                     <input id={`status${st.code}`} name={st.code} value={st.status} type="checkbox" onChange={()=>handleChange(idx)} checked={st.status}  /> 
                     <label htmlFor={`status${st.code}`}></label>
                     </div>
                </td>
		</tr>

    )}
		
</tbody>

</table></div>

		</div>	 
        <span style={{color:'red'}}>{errors.fromClass && errors.fromClass}</span><br/>
<span style={{color:'red'}}>{errors.toClass && errors.toClass}</span><br/>

<span style={{color:'red'}}>{errors.studentList && errors.studentList}</span><br/>
        </div>	 </div>	 </div>	 </div>	 }


<button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Promote Selected Students</i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }  



        
  </div>
	</div>

</div>

</div></div>

                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
 );
        
}

export default React.memo(Promotion) 