import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, staffName, config, sectionID, selectStyles} from '../component/include'
import Select from 'react-select'
import { Alerts, WarningModal } from '../component/notify'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'
const StudentReview =()=>{

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    const [errors, setErrors] = useState({});
    const [student, setStudent] = useState([]);
    const [review, setReview] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false); 
const [profile, setProfile]=useState({
    student:[],
    review:'',
    dateReview:'',
    reviewBy:staffName,
    visibleToStudent:'YES',
    spotType:'',
    criteria:'',
    allotedPoint:''
});
const [allCriteria, setAllCriteria] = useState([]);
const [criteria, setCriteria] = useState([]);
const fetchCriteria =()=>{
    const fd = new FormData(); 
    var sql ="Select code, criteriaName, spotType from tbl_spot_criteria "; 
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result => setAllCriteria(result.data))   
 }

 const handleChangeSpot = (e)=>{
    const {name, value} = e.target;
    setProfile({...profile, [name]: value});
setCriteria(allCriteria.filter(item=>item.spotType===value))
    setErrors({...errors, [name]:''})
 }


const handleChange = (e)=>{
    const {name, value} = e.target;
    setProfile({...profile, [name]: value});
    setErrors({...errors, [name]:''})
 }
const handleSelect = (option, action)=>{
    setProfile({...profile, [action.name]: option});
    fetchReview(option.value)
 }
   
 const handleSelectCriteria = (option, action)=>{
    setProfile({...profile, [action.name]: option});
 }

    const handleFetchStudent =()=>{
        setNotice({...notice,  isLoading: true})
        var sql ="Select studentName, code, admissionNumber  from tbl_students where sectionID ='"+sectionID+"'"; 
        const fd = new FormData(); 
        fd.append("jwt", Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>setStudent(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,  isLoading: false})
    })
    }

    const handleDelete = (code)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
              const fd = new FormData(); 
              fd.append("jwt", Token);
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller/tbl_student_review'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
               fetchReview()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }
    const fetchReview =(code=profile.student.value)=>{
        setNotice({...notice, isLoading: true})
        var sql ="Select r.ID, s.studentName, s.admissionNumber, r.review, r.dateReview, r.allotedPoint, r.spotType, r.criteria, r.visibleToStudent, r.code  from tbl_student_review r, tbl_students s where s.code =r.studentCode and r.studentCode ='"+code+"' "; 
        const fd = new FormData(); 
        fd.append("jwt", Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setReview(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isLoading: false})
    })
    }
    
    

    const tableHeader = [
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>


<a href="#!" data-toggle="modal" className="btn btn-danger btn-sm"  data-target={`#warningdialog-${row.code}`} title="Delete"><i className="icofont icofont-delete-alt"></i></a>&nbsp;
      
      
       <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 

        </div>, editable: false},

        {dataField: 'studentName', text: 'Student Name', sort: true, editable: false},
          {dataField: 'review',  text: 'Review',  sort: true, formatter:(cell)=><span style={{display:'block',  width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
          {dataField: 'spotType', text: 'Spot Type', sort: true, editable: false, formatter: (cell)=>cell==='Red Spot'? <span className="pcoded-badge label label-danger">{cell}</span>: <span className="pcoded-badge label label-success">{cell}</span>},
          {dataField: 'allotedPoint', text: 'Point', sort: true, editable: false},
        {dataField: 'dateReview',  text: 'Date',  editor: {
            type: Type.DATE
          }},
        {dataField: 'visibleToStudent',  text: 'Public',
        editor: {
            type: Type.SELECT, options:[
                        {value:'YES', label:'YES'},
                        {value:'NO', label:'NO'}
            ] }, sort: true}
     ];


    const StudentTable=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
  
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData(); 
            fd.append("jwt", Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
            let url = ServerUrl+'/updateById_controller/tbl_student_review'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>

      <div className="dt-buttons btn-group pull-right"> 
      
			


                 
                 <form method='POST' action={ServerUrl+'/printout/student_review.php?studentCode='+profile.student.value+'&token='+userToken} target='_blank'>
                 <button data-toggle="modal" type='button'  data-target='#addStudentReview' className="btn btn-outline-success" > <i className="fa fa-plus"></i>Add New</button>	
                <input type='hidden' name='jwtToken' value ={Token} />
                <button type="submit" className="btn btn-outline-success" > <i className="fa fa-print"></i> Print</button>
        
        </form>
    </div> 
      
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    
     


function submit(event){
event.preventDefault()
    let errors = {};   
    if(profile.student.length===0){
        errors.student ='Please select student';
    } 
    if(!profile.review){
        errors.review ='Please enter review';
    } 
    if(!profile.dateReview){
        errors.dateReview ='Please enter date review';
    } 

    if(!profile.spotType){
        errors.spotType ='Please select spot type';
    } 
    if(!profile.allotedPoint){
        errors.allotedPoint ='Please select alloted point';
    } 
    if(!profile.criteria.length==0){
        errors.criteria ='Please select criteria';
    } 

setErrors(errors)
if(Object.keys(errors).length === 0){
    
            setNotice({...notice, isLoading: true})    
            
            const fd = new FormData(); 
            fd.append("jwt", Token);
                let formData = profile; 
               for(let k in formData){
                   fd.append(k,formData[k]);
               };   
               fd.append('studentCode', profile.student.value);
               fd.append('criterials', profile.criteria.value);

               fd.append('code', 'r'+Math.random().toString(36).substr(2,9));
             
             let url = ServerUrl+'/save_controller/tbl_student_review'
                      axios.post(url, fd, config)
                .then(response =>{
                    if (response.data.type ==='success'){
                        Alerts('Saved!', 'success', response.data.message)
                    } else{
                        Alerts('Error!', 'danger', JSON.stringify(response.data))
                }
                        
                })
                .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
    .finally(()=>{
        setNotice({...notice, 
            isLoading: false})
            fetchReview()
            setIsSubmitting(false);
            setCriteria([])
            setProfile({
                student:[],
                review:'',
                dateReview:'',
                reviewBy:staffName,
                visibleToStudent:'YES',
                spotType:'',
                criteria:'',
                allotedPoint:''
            }) 
        })


let close =   document.getElementById('closeStudentReveiew')
close.click();
            }
        }



useEffect(()=>{   
    handleFetchStudent()
    fetchCriteria()
},[]);

        return (  
            <React.Fragment>
   <Template menu="Student" submenu='Student Review'>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper"> 

        <PageHeader title="Student Review">
        <li className="breadcrumb-item"><a  href="/dashboard"> <i className="fa fa-dashboard"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="/students/view_students">Student</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Review</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



                <div className="modal fade " id='addStudentReview' tabIndex="-1" role="dialog">
      <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content ">
              <div className="modal-header">
                  <h6 className="modal-title"><i className='fa fa-info'></i> Add a new Review</h6>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
              <div className="modal-body">

			  <div className="row">
              <section className="col-md-6">
    <div className="form-group">
    
    <label >Date Review </label>
   <input type='date' className={errors.dateReview  ? 'form-control form-control-danger' : 'form-control'} name="dateReview" value={profile.dateReview} onChange={handleChange} /> 
   
		<span style={{color:'red'}}>{errors.dateReview && errors.dateReview}</span>  
                </div>
        </section>
        <section className="col-md-6">
    <div className="form-group">
    
    <label >Review Type</label>
<select value={profile.spotType} name='spotType' onChange={handleChangeSpot} className={errors.spotType ? 'form-control form-control-danger' : 'form-control'}>
<option value="" disabled > Select Type</option>
<option value="Blue Spot" >Blue Spot (1)</option>
<option value="Red Spot" >Red Spot (-1)</option>
</select> 
   
		<span style={{color:'red'}}>{errors.spotType && errors.spotType}</span>  
                </div>
        </section>


        <section className="col-md-6">
    <div className="form-group">
    
    <label >Select Criteria </label>
    <Select   options={criteria&&criteria.map((data, idx)=> {
                                    return {key:idx, value: data.code, label: data.criteriaName, index:idx };
                                  })}
onChange={handleSelectCriteria}  name="criteria" value={profile.criteria} styles={selectStyles} />

                </div>
        </section>

       

        <section className="col-md-6">
    <div className="form-group">
    
    <label >Alloted Point </label>
    <select value={profile.allotedPoint} name='allotedPoint' onChange={handleChange} className={errors.allotedPoint ? 'form-control form-control-danger' : 'form-control'}>
<option value="" disabled > Select Point</option>
<option value="1" >1</option>
<option value="2" >2</option>
<option value="3" >3</option>
</select>
		<span style={{color:'red'}}>{errors.allotedPoint && errors.allotedPoint}</span>  
                </div>
        </section>

        <section className="col-md-12">
    <div className="form-group">
    
    <label >Description </label>
   <textarea type='text' rows={3} className={errors.review  ? 'form-control form-control-danger' : 'form-control'} name="review" value={profile.review} onChange={handleChange} > {profile.review}</textarea>  
   <span style={{color:'red'}}>{errors.review && errors.review}</span> 
   <span style={{color:'red'}}>{errors.student && errors.student}</span> 
                </div>



                <div className="col-md-6">
           <div className="form-group">
         <label> Visible to Student</label>
         <div className="form-radio">
                <div className="radio radio-inline"><label title='Yes'>
						<input type="radio" checked={profile.visibleToStudent ==="YES"} onChange={handleChange} value="YES" name="visibleToStudent" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label title='No'>
						<input type="radio" checked={profile.visibleToStudent ==="NO"} onChange={handleChange} value="NO" name="visibleToStudent" />
										<i className="helper"></i>No</label></div>

								</div>
            </div>
     
     </div>
        </section>


				</div>
				
              </div>
              <div className="modal-footer">
                  <button type="button"  id='closeStudentReveiew' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
                  <button type="button" onClick={submit} className="btn btn-inverse">Save</button>
              </div>
          </div>
      </div>
  </div>

                <div>
                
                <div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Select Student</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-12">
    
        <div className="form-group">
        
        <label >Select Student </label>
        <Select  options={student&&student.map((list, idx)=> {
                                       return {key:idx, value: list.code, label:list.studentName, admissionNumber:list.admissionNumber }
                                     })
                                }    
                    getOptionLabel={option =>option.label +' '+ option.admissionNumber}    
        onChange={handleSelect}  name="student" value={profile.student}  />   
                    </div>
			</section>

									</div>
										
							</div>
							</div>

							</div>
                </div>




           {profile.student.length!==0?     <div className="row">
                                    
                <div  className="col-md-12" >                   
<div className="card z-depth-0">
            <div className="card-header">
                <h5>Student Review</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <StudentTable data={review}  columns={tableHeader}  />}
            </div></div>

    </div></div>
            
            </div>:''}
    
                </div>
                </div>
          {/* The Page Body close here */}

            </div>
            </div>
</Template> 
</React.Fragment>
 );
        
}
export default React.memo(StudentReview) 