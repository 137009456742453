import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  staffCode,  Token, config} from '../component/include'
import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

const ChangePassword =()=>{


    const [notice, setNotice] = useState({
      isLoading : false,
      isDataFetching: true
  });
  
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const [errors, setErrors] = useState({});
  const portalCode = Cookies.get('tkauscd')?CryptoJS.AES.decrypt(Cookies.get('tkauscd'), 'kayus').toString(CryptoJS.enc.Utf8):'null'

  const [content, setContent] = useState({
      code:staffCode,
      portalCode:portalCode,
      oldPassword:'',
      password:'',
      confirmPassword:''
  })  	
  
  const handleChange =(event)=>{
      const {name, value} = event.target;
      setContent({...content, [name] : value });
      setErrors({...errors, [name]:''});
  }
  
  const handleSubmit = (event) =>{
      event.preventDefault();         
      setErrors(ValidateInput(content));
        setIsSubmitting(true);
    }
    
    useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors, isSubmitting])
    
    
    const ValidateInput =(values)=>{
          let errors = {};   
          if(values.password!==values.confirmPassword){
            errors.confirmPassword ='Password does not match.';
        }

        if(values.password.length <6){
            errors.password ='Please provide a minimum of 6 character length password.';
        }
          if(!values.password){
              errors.password ='This field is required';
          }
          if(!values.portalCode){
            errors.portalCode ='This field is required';
        }
          if(!values.confirmPassword){
              errors.confirmPassword ='This field is required';
          }
          
         
          return errors;
          }
  

          function submit(){  
              setNotice({...notice, 
                isLoading: true})     
            
                let formData = content;
                const fd = new FormData();
                fd.append('jwt', Token);
                for(let k in formData){
                fd.append(k, formData[k])
                }
                
                 let url = ServerUrl+'/update_controller/tbl_staff_New_password'
                axios.post(url, fd, config)
                .then(response =>{
                 if(response.data.type ==='info'){
                  Alerts('Saved!', 'default', response.data.message)
                  window.open('/logout', '_self')
                    } else{
                  Alerts('Error!', 'danger', response.data)
                    }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                  setNotice({...notice, isLoading: false })
                  setIsSubmitting(false);
                }) 
            }

        return (  
            <React.Fragment>
   <Template menu='Dashboard' submenu=''>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Change Passowrd">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-user"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Staff</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Password</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
                <div  className="row  ">
							
                               
                          <div className="col-sm-12">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Update Password</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
	
         	<div  className="row">
									
		
          
            <section className="col-md-6">
					<div className="form-group">
						<label >New Password</label>
                        <input  type="password" id="account-pass" placeholder="Enter new password"  onChange={handleChange}   name="password" value={content.password} className={errors.password  ? 'form-control form-control-danger' : 'form-control '} />	
                        <span style={{color:'red'}}>{errors.password && errors.password}</span>
			</div>
            </section>


            <section className="col-md-6">
            <div className="form-group">
						<label >Repeat New Password</label>
                        <input autoComplete="off" onChange={handleChange} placeholder="Repeat new password"  name="confirmPassword" value={content.confirmPassword} className={errors.confirmPassword  ? 'form-control form-control-danger' : 'form-control '} type="password" id="account-confirm-pass" />	
                        <span style={{color:'red'}}>{errors.confirmPassword && errors.confirmPassword}</span>
			</div></section>
            </div>
											

                                        <div  className="row">
                                        <section className="col-md-4">
	<footer className="pull-left">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-warning "> <i className='fa fa-lock'></i> Change Password</button>
	
								</footer>	
                                </section>			
							</div>
							</div>
                            </div>
                            </div>
                            
                            </div>



   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
</React.Fragment>
 );
        
}

export default React.memo(ChangePassword) 