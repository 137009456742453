import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'

import Select from 'react-select'
import {ServerUrl,  Token, config, schoolName, ImagesUrl} from '../component/include'
import {getTime, formatGroupLabel} from '../component/globalFunction'
const TimetableDate =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [scheduleList, setScheduleList] = useState([]);
    const [schoolClass, setSchoolClass]=useState([]);
    const [subject, setSubject]= useState([])
    const [lessonPlan, setLessonPlan] = useState([]);
    const [timetable, setTimetable]=useState({
    fromDate:'',
    schoolClass:[]
    })
    const [schoolInfo, setSchoolInfo]=useState([])

    const [CurrentSession, setCurrentSession] = useState('')
   
    const fetchSchoolInfo =()=>{
        const fd = new FormData();
            fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_basic_details'
        axios.post(url, fd, config).then(result=>setSchoolInfo(result.data))

    }
    const  handleSession =()=>{

        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        }
    const fetchSchoolClass =()=>{
        const fd = new FormData();
            fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1/'
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 

    }

    const fetchSubject =()=>{
        const fd = new FormData();
            fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }
   

    
    const handleChange=(event)=>{
        let {name, value} = event.target
        setTimetable({...timetable, [name]:value});
       
    }

    const handleSelect = (option, action)=>{
        setTimetable({...timetable, [action.name]: option});
        setErrors({...errors, [action.name]:''})  

    }

    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(timetable));
        setIsSubmitting(true);
    } 

    const ValidateErrors=(values)=>{
        let errors = {};  
       if(!timetable.fromDate){
            errors.fromDate ='Please select date';
        }

         if(values.schoolClass.length===0){
                errors.schoolClass ='Please select class';
            }
        return errors;
        }


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
        fetchTableList()
    }
    },[errors])

    const  fetchTableList =()=>{  
        setNotice({...notice, 
            isLoading: true})

        var date = new Date(timetable.fromDate)
            const day = 1000 * 60 * 60 * 24
            var answer = []
            for(var i =0; i<7; i++){
                answer.push(date.toISOString().slice(0,10))
                date.setTime(date.getTime()+day)
            }

            const fd = new FormData();
            fd.append('jwt', Token);
            let sql = "Select lessonPlan, lessonDate, day from tbl_lesson_plan where sectionID = '"+timetable.schoolClass.value+"' and sessionYear = '"+CurrentSession+"' and lessonDate IN("+answer.map(data=>'"'+data+'"')+")"

          let url = ServerUrl+'/fetchBySql_controller'
           fd.append("sql", sql)
           axios.post(url, fd, config).then(result =>{
               setScheduleList(result.data)
           })
           .finally(()=>{
                setNotice({...notice, 
                    isLoading: false})
            })  
    }

   

const openModal=(item)=>{
    setLessonPlan(item)
     document.getElementById('openModal').click()
     
}
    
 const getSubject =(code)=>{
    const result = subject&&subject.filter(list=>list.code===code);
    const answer = result.map(c=>c.subjectName);
    return  answer  
}


const getTableDetails=(data)=>{
    const list = JSON.parse(data)
    return list.map((item, id)=>
    <div key={id} className="attachment-block text-center ">  
   <b className="text-blue">{getSubject(item.subject)}</b><br/>
<strong className="text-green">{getTime(item.startTime)+ ' ~ '+getTime(item.endTime)} </strong>
<br/><a href='#!' onClick={()=>openModal(item)} className="text-blue"> <i className='fa fa-th'></i> View</a><br/>
      
        <br/>
    </div>
    )     
}




useEffect(()=>{
    fetchSchoolInfo()
    fetchSchoolClass()
    fetchSubject()
    handleSession()
},[]);



        return (  
            <React.Fragment>
   <Template menu="Timetable" submenu='Report'>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Class Lesson Plan">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Lesson Plan</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
 <button type="button" data-toggle="modal"  data-target='#lessonplan' id="openModal" className="btn display-none"></button>

                <div id="lessonplan" className="modal fade in" role="dialog" aria-hidden="false" >
        <div className="modal-dialog modal-lg">
            <div className="modal-content">
            <div className="modal-header">
                  <h4 className="modal-title">Lesson Plan</h4>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
  <span aria-hidden="true">&times;</span>
  </button>
              </div>
           {lessonPlan.length!==0?     <div className="modal-body">
                    <div className="row form-group">
                        <label className="col-md-3 bold pull-right">Class</label>
                        <div className="col-md-9">{timetable.schoolClass.label}
                        </div>
                    </div>

                    <div className="row form-group">
                        <label className="col-md-3 bold pull-right">Subject</label>
                        <div className="col-md-9">{getSubject(lessonPlan.subject)}
                        </div>
                    </div>

                    <div className="row form-group">
                        <label className="col-md-3 bold pull-right">Date & Time</label>
                        <div className="col-md-9">{ getTime(lessonPlan.startTime)+ ' ~ '+getTime(lessonPlan.endTime)} 
                        </div>
                    </div>

                    <div className="row form-group">
                        <label className="col-md-3 bold pull-right">Chapter</label>
                        <div className="col-md-9"><b>{lessonPlan.chapter}</b>
                        </div>
                    </div>

                    <div className="row form-group">
                        <label className="col-md-3 bold pull-right">Topic</label>
                        <div className="col-md-9"><span style={{display:'block',  width:400, whiteSpace:'normal', wordWrap:'break-word' }}>{lessonPlan.topic}</span>
                        </div>
                    </div>
                </div>:''}
                <div className="modal-footer">
                    <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>






<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Class Lesson Plan</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximize full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
        <section className="col-md-6">
      <div className="form-group">
						<label >Select Class<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            schoolClass.map((list, idx)=> {
                                return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} className={errors.schoolClass ? 'form-control form-control-danger' : ''}  name="schoolClass" value={timetable.schoolClass}   /> 
								
<span style={{color:'red'}}>{errors.schoolClass && errors.schoolClass}</span>				
											</div>	

</section>


      <section className="col-md-6">
      <div className="form-group">
<label >Select Date</label>
<input type="date" className={errors.fromDate ? 'form-control form-control-danger' : 'form-control'} name="fromDate"  onChange={handleChange} value={timetable.fromDate} />
<span style={{color:'red'}}>{errors.fromDate && errors.fromDate}</span>
   </div>

</section>
</div>

<div className="row">
<section className="col-md-12">
<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-inverse "><i className="fa fa-search"></i> Search</button>
								</footer>
                                </section>
		</div>	
              
 </div> </div> 
							
</div>

</div>   





{scheduleList.length!==0?                     
<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
                <tr>
                    <td >
                    <div className='row'>
                        <div className='col-md-3'>
                    <img id="viewSchoolLogo2"  className="img-100 pull-right" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
                    </div>

                    <div className='col-md-6'>
<h4 style={{textAlign:'center'}}><b>{schoolName}</b></h4>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}>  {st.mobile} &nbsp; {st.altMobile} </div>
 <div style={{textAlign:'center'}}>  {st.email} </div>
 </div></div>
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>{CurrentSession}  Class Lesson Plan </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{timetable.schoolClass.label}</b></td>
                    
                </tr>
            </tbody>
       </table>
       {scheduleList.length!==0?
       <table className="table table-bordered">
            <thead>
                <tr>
                    <th>Monday <br/> {scheduleList&&scheduleList.map(list=>list.day==='Monday'? list.lessonDate:' ')}</th>
                    <th>Tuesday <br/> {scheduleList&&scheduleList.map(list=>list.day==='Tuesday'? list.lessonDate:' ')}</th>
                    <th>Wednessday  {scheduleList&&scheduleList.map(list=>list.day==='Wednessday'? list.lessonDate:' ')}</th>
                    <th>Thursday<br/> {scheduleList&&scheduleList.map(list=>list.day==='Thursday'? list.lessonDate:' ')} </th>
                    <th>Friday <br/> {scheduleList&&scheduleList.map(list=>list.day==='Friday'? list.lessonDate:' ')}</th>
                    <th>Saturday <br/> {scheduleList&&scheduleList.map(list=>list.day==='Saturday'? list.lessonDate:' ')}</th>
                    <th>Sunday <br/> {scheduleList&&scheduleList.map(list=>list.day==='Sunday'? list.lessonDate:' ')}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Monday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.lessonPlan)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Tuesday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.lessonPlan)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Wednessday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.lessonPlan)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Thursday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.lessonPlan)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Friday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.lessonPlan)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Saturday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.lessonPlan)}</span>:' ')}
                        </td>
                        <td>
                    {scheduleList&&scheduleList.map((list, index)=>list.day==='Sunday'? <span key={index} style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{getTableDetails(list.lessonPlan)}</span>:' ')}
                        </td>

                </tr>
            </tbody> 
        </table>:<div className="alert alert-danger background-danger">No Record Found</div>}

 </div>	 </div> 


 </div>
        </div>:''}





     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</Template> 
</React.Fragment>
 );
        
}

export default React.memo(TimetableDate) 